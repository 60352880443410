<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Latest Digital Trace
            .header-cta
                .flex.items-center
                    .block
                        button.btn-primary.m-btn-icon(
                            v-if="checkFeatureByAcl('target_tags', 'create')"
                            @click="openModal(true)"
                        )
                            IconSet(type="add")
                            span.m-hide.ml-3 New Digital Trace

        .card--table__body
            .box-filter-table.p-4.pt-0
                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    input.field-style(
                        v-model="keyword"
                        type="text"
                        placeholder="Search Keyword..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                //- .field.mr-3(style="min-width: 180px;")
                    select.field-style()
                        option All types
                        option Email
                        option MSISDN
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
                .field.box-find(class="lg:mr-3 lg:mb-0 mr-0 mb-4")
                    .relative
                        .field.relative.w-full
                            input.field-style(
                                placeholder="Select user (min 3 characters)"
                                v-model="user_keyword"
                                @keyup="searchUser()"
                            )
                            .field-icon
                                IconSet(type="search")

                            .box-find-selected(v-if="user_selected")
                                .flex.items-center.justify-between
                                    .flex.items-center
                                        figure.mr-3
                                            //- img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                        span {{user_keyword}}
                                    button.btn-icon-24(@click="setEmptyUser" v-tooltip="'Remove user'")
                                        IconSet(type="close")

                        .p-4.absolute.container-box-users(v-if="status_users.status === 'loading'")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="24")
                        .p-4.absolute.container-box-users(v-else-if="user_keyword.length > 2 && list_users.data && list_users.data.length > 0")
                            .small-heading Choose User
                            .box-users
                                .item-user(
                                    v-for="(user, index) in list_users.data"
                                    :key="user.id"
                                    @click="selectUser(user)"
                                )
                                    span {{ user.name }}
                        .p-4.absolute.container-box-users(v-else-if="list_users.data && list_users.data.length == 0")
                            | No data available.

                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                    select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )


            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        template(v-else-if="list && list.data && list.data.length > 0")
                            tr(v-for="(data,index) in list.data" :key="data.id")
                                td {{ data.keyword?data.keyword:'-' }}
                                td {{ data.type?data.type:'-' }}
                                td {{ toString(data.cases) }}
                                td(style="padding-right:0px !important")
                                    .text-sm.truncate(style="width: 240px;") {{ data.description?data.description:'-' }}
                                td
                                    //- .w-48
                                    //-     template(v-if="data.notes && data.notes.length > 0")
                                    //-         .text-sm.note(@click="getNotes(true, data.id, data.keyword)") {{data.notes&&data.notes.notes?data.notes.notes:'No notes'}}
                                    //-     template(v-else)
                                    //-         button.link.text-sm(@click="getNotes(true, data.id, data.keyword)") {{data.notes&&data.notes.notes?data.notes.notes:'Add a note'}}
                                    .w-48
                                        template(
                                            v-if="!checkFeatureByAcl('target_tags', 'create') && data?.notes == undefined"
                                        ) -
                                        button.link.text-sm.truncate.text-left(style="width: 240px;"
                                            v-else
                                            @click="getNotes(true, data.id, data.keyword)"
                                        ) {{data?.notes?.notes?data?.notes?.notes:'Add a note'}}
                                td {{ data.created_by && data.created_by.name?data.created_by.name:'-' }}
                                td {{ data.created_at?data.created_at:'-' }}
                                td
                                    .flex.items-center.justify-end(v-if="checkFeatureByAcl('target_tags', 'search') || checkFeatureByAcl('target_tags', 'detail')")
                                        .mr-8
                                            router-link.btn.btn-small.btn-primary-o.mr-2(
                                                v-if="checkFeatureByAcl('target_tags', 'search')"
                                                :to="{name: 'pageTargetTagsDetail', params: {id:data.id, source:'external'}}"
                                            ) Search
                                            router-link.btn.btn-small.btn-primary-o(
                                                v-if="checkFeatureByAcl('target_tags', 'detail')"
                                                :to="{name: 'pageTargetTagsDetail', params: {id:data.id, source:'internal'}}"
                                            ) Detail
                                            //- :to="'/target-tags/'+data.id+'/detail'"

                                //- td
                                    .flex.items-center.justify-end.w-full
                                        router-link(:to="'/device-track/'+data.id+'/detail'").btn-small.btn-primary-o Search IMSI
                                td
                                    .flex.items-center.justify-end.w-full(v-if="checkFeatureByAcl('target_tags', 'edit') || checkFeatureByAcl('target_tags', 'delete')")
                                        button.btn-icon-40.m-hide(
                                            v-if="checkFeatureByAcl('target_tags', 'edit')"
                                            @click="openModal(true, data)"
                                            v-tooltip="'Edit'"
                                        )
                                            IconSet(type="edit")
                                        button.btn-icon-40.m-hide(
                                            v-if="checkFeatureByAcl('target_tags', 'delete')"
                                            @click="deleteData(data.id, data.keyword)"
                                            v-tooltip="'Delete'"
                                        )
                                            IconSet(type="delete")                                        
                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available
            
            .box-table-footer(v-if="status_list.status !== 'loading'")
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                            )

    //- popup 

    .popup-layer.is-show(v-if="openPopUp")
        .popup-container
            .popup-container__header
                .flex.items-center(v-if="!edit")
                    h2.text-xl New Digital Trace
                template(v-else)
                    h2.text-xl Edit Digital Trace
                button.btn-icon-40(
                    @click="openModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                //- p.mb-8 Please choose the a email/MSISDN to get Target's information

                //- .flex.items-center.gap-4.mb-8
                    .ui-radio.w-full
                        input(type="radio" name="type")
                        .style-radio
                            .block
                                .radio
                            .flex.items-center.justify-between.w-full
                                span Email
                    .ui-radio.w-full
                        input(type="radio" name="type")
                        .style-radio
                            .block
                                .radio
                            .flex.items-center.justify-between.w-full
                                span MSISDN
                
                //- label.mb-2.block.text-white Choose MSISDN/Email
                
                //- .relative
                    //- searching
                    .field.w-full
                        input.field-style(
                            type="text"
                            placeholder="Search MSISDN/Email here..."
                            v-model="typing_search"
                            @input="searchHandler()"
                        )
                        .field-icon(v-if="typing_search.length < 3")
                            IconSet(type="search")
                        
                        button.btn-close-search(v-else @click="clearSearch()")
                            IconSet(type="x")                    

                    //- search result
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Type<sup>*</sup>
                    .field__form.w-full
                        template(v-if="!edit")
                            .relative
                                select.field-style#filter-record-size(style="min-width:160px" v-model="tmpType")
                                    option(v-for="(s,index) in typeValue" :key="index" :value="s.value") {{ s.label }}
                                .field-icon
                                    IconSet(type="chevron-down")
                        template(v-else)
                            .relative
                                select.field-style#filter-record-size(style="min-width:160px" v-model="tmpType" disabled)
                                    option(v-for="(s,index) in typeValue" :key="index" :value="s.value") {{ s.label }}
                                .field-icon
                                    IconSet(type="chevron-down")
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Keyword<sup>*</sup>
                    .field__form.w-full
                        //- input.field-style.w-full(:type="text" v-model="tmpKeyword" placeholder="Write keyword here...")
                        //- .py-3.mr-2.message.text-xs.text-red-500(v-if="errorTmpKeyword !== ''") {{ errorTmpKeyword }}
                        template(v-if="!edit")
                            input.field-style.w-full(:type="tmpType == 'msisdn'?'number':'text'" @input="inputKeyword" v-model="tmpKeyword" placeholder="Write keyword here...")
                            .py-3.mr-2.message.text-xs.text-red-500(v-if="errorTmpKeyword !== ''") {{ errorTmpKeyword }}
                        template(v-else)
                            input.field-style.w-full(:type="tmpType == 'msisdn'?'number':'text'" @input="inputKeyword" v-model="tmpKeyword" placeholder="Write keyword here..." disabled)
                            .py-3.mr-2.message.text-xs.text-red-500(v-if="errorTmpKeyword !== ''") {{ errorTmpKeyword }}
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Case<sup>*</sup>
                    .field__form.w-full
                        .relative(v-if="status_case_list.status === 'loading'")
                            .field-style
                                .field-style--mirroring.field-style--loading
                                    Spinner(show="true" size="32")
                        template(v-if="!edit")
                            .field-multiple-select
                                multiselect(
                                    v-if="case_management_list.data && case_management_list.data.length > 0"
                                    :value="casesValue",
                                    :options="case_management_list.data ? case_management_list.data : []",
                                    :multiple="true",
                                    placeholder="Select Case",
                                    label="operation_name",
                                    track-by="id",
                                    @input="onChange"
                                )
                            .py-3.mr-2.ext-xs.text-red-500(v-if="$v.model.case_id.$error") This case name is required.
                        template(v-else)
                            .field-multiple-select
                                multiselect(
                                    v-if="case_management_list.data && case_management_list.data.length > 0"
                                    :value="casesValue",
                                    :options="case_management_list.data ? case_management_list.data : []",
                                    :multiple="true",
                                    placeholder="Select Case",
                                    label="operation_name",
                                    track-by="id",
                                    @input="onChange"
                                    disabled
                                )
                            .py-3.mr-2.ext-xs.text-red-500(v-if="$v.model.case_id.$error") This case name is required.
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Description
                    .field__form.w-full
                        textarea.field-style.w-full(v-model="model.description" placeholder="Write a description here...")
                .field.w-full(v-if="!edit")
                    label.field__label.text-sm.font-bold Notes
                    .field__form.w-full
                        textarea.field-style.w-full(v-model="model.notes" placeholder="Write a notes here...")
            .popup-container__footer
                .flex.justify-end.w-full(v-if="!edit")
                    template(v-if="tmpKeyword && tmpKeyword.length>0 && errorTmpKeyword == ''")
                        button.btn-primary(@click="submit($event)") Search Digital Trace
                    template(v-else)
                        button.btn-primary.btn-disabled(disabled) Search Digital Trace 
                template(v-else)
                    template(v-if="tmpKeyword && tmpKeyword.length>0 && errorTmpKeyword == ''")
                        button.btn-primary(@click="submit($event)") Save & Search Digital Trace
                    template(v-else)
                        button.btn-primary.btn-disabled(disabled) Save & Search Digital Trace 

    .popup-layer(
        v-bind:class="{'is-show' : openNote}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{targetTagsSelected}}'s Notes
                button.btn-icon-40(
                    @click="getNotes(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4(v-if="checkFeatureByAcl('target_tags', 'create') ")
                    .field.w-full.mb-2
                        textarea.field-style(
                            v-model="tmpNote"
                            placeholder="Write new note here..."
                            style="min-height:60px"
                        )
                    .flex.justify-end
                        template(v-if="tmpNote && tmpNote.length>0")
                            button.btn-primary.btn.small(@click="addNote") Add note
                        template(v-else)
                            button.btn-primary.btn.small.btn-disabled(disabled) Add note
                .mb-4
                    .small-heading History Notes
                    template(v-if="status_notes_list.status == 'loading'")
                        .flex.flex-wrap.items-center.justify-center
                            Spinner(show="true" size="48")

                    .mb-4.pb-4.border-b(v-else-if="notes_list && notes_list.length > 0" v-for="note in notes_list")
                        p.mb-1 {{note.notes}}
                        small.text-xs.opacity-75 {{note.created_at}} • added by {{note.created_by?note.created_by:'-'}}

                    .flex.flex-wrap.items-center.justify-center(v-else)
                        strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                    .block                            
                        template(v-if="status_notes_list.status != 'loading' && (notes_list && notes_list.length > 0)")
                            small.text-xs.opacity-75(v-if="!is_loading_next") {{notes_list.length}} of {{data_pagination.total_data}} items
                            .flex.flex-wrap.items-center.justify-center
                                template(v-if="is_loading_next")
                                    Spinner(show="true" size="48")
                                template(v-else)
                                    template(v-if="data_pagination.current_page<data_pagination.total_page")
                                        button.btn-small.btn-primary(@click="handleNextNotes") Load More                         
    
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import { required, maxLength} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'

export default {
    name: 'pageTargetTagsIndex',
    components: {
        IconSet,
        DateRangePicker,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        Multiselect,
    },

    data() {
        return {
            tableThead: [
                {
                    name: 'Keyword',
                },{
                    name: 'Type',
                },{
                    name: 'Case',
                },{
                    name: 'Description',
                },{
                    name: 'Notes',
                },{
                    name: 'Created By',
                },{
                    name: 'Created At',
                },
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            openPopUp: false,
            
            tmpKeyword: null,
            tmpType: 'msisdn',
            errorTmpKeyword: '',
            errorTmpType: '',
            model:{
                case_id: [],
                description: '',
                notes:'',
            },
            user_keyword:'',
            user_id:'',
            user_selected:false,

            openNote: false,

            targetTagsIdSelected: null,
            targetTagsSelected: null,
            tmpNote: null,
            edit:false,
            editData:null,
            typeValue: [
                {
                    label: 'MSISDN', 
                    value: 'msisdn'
                },
                {
                    label: 'Name', 
                    value: 'name'
                },
                {
                    label: 'Email', 
                    value: 'email'
                },
            ],            
            // openPopUp: false,
            // popUpWatchlist: false,
            // dateRange: {
            //     startDate: '2022-01-01',
            //     endDate: '2022-01-31',
            // },

            // default_ranges: {
            //     'Today': [moment().toDate(), moment().toDate()],
            //     'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
            //     'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
            //     'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            // },

            // data: [
            //     {
            //         target: '0812345678901',
            //         type: 'MSISDN',
            //         created_by: 'Operator 1',
            //         created_at: '10/20/22 12:00:21',
            //     },
            //     {
            //         target: '0812345678901',
            //         type: 'MSISDN',
            //         created_by: 'Operator 1',
            //         created_at: '10/20/22 12:00:21',
            //     },
            //     {
            //         target: 'mail@gmail.com',
            //         type: 'Email',
            //         created_by: 'Operator 1',
            //         created_at: '10/20/22 12:00:21',
            //     },
            //     {
            //         target: 'mail@gmail.com',
            //         type: 'Email',
            //         created_by: 'Operator 1',
            //         created_at: '10/20/22 12:00:21',
            //     },
            //     {
            //         target: '0812345678901',
            //         type: 'MSISDN',
            //         created_by: 'Operator 1',
            //         created_at: '10/20/22 12:00:21',
            //     },
            //     {
            //         target: '0812345678901',
            //         type: 'MSISDN',
            //         created_by: 'Operator 1',
            //         created_at: '10/20/22 12:00:21',
            //     },
            // ],

            // typing_search: '',
            // targetSelected: false,

            // dataResult: [
            //     { key: '1234567890123451'},
            //     { key: '1234567890123452'},
            //     { key: '1234567890123453'},
            //     { key: '1234567890123454'},
            //     { key: '1234567890123455'},
            //     { key: '1234567890123456'},
            //     { key: '1234567890123457'},
            //     { key: '1234567890123458'},
            //     { key: '1234567890123459'},
            //     { key: '1234567890123450'},
            // ],
        }
    },
    validations() {
        return {
            model: {
                case_id: {
                    required,
                },
                description: {
                    maxLength: maxLength(255)
                },
                notes: {
                    maxLength: maxLength(255)
                },
            },
        }
    },
    computed: {    
        ...mapState('auth', ['user']),
        ...mapState('targetTags', [
            'status_list',
            'list',
            'status_notes_list',
            'notes_list',
            'is_loading_next',
            'data_pagination',            
        ]),
        ...mapState({
            status_users: (state) => state.users.status_list,
            list_users: (state) => state.users.list,
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
        }),
        route_name() {
            return this.$route.name;
        },
        casesValue: {
            get() {
                if (!this.model.case_id) return null
                // console.log('=====onChange:get')
                // console.log(this.model.case_id)
                try {
                    return this.model.case_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    // console.log('error casesvalue')
                    // console.log(error)
                    return null
                }
            },
            set(v) {
                console.log('=====casesValue:set')
                console.log(v)
                this.model.case_id=v.map(value => {
                    let rObj = {}
                    console.log(rObj)
                    rObj['id'] = value.id
                    console.log(rObj)
                    return rObj
                })
                console.log(this.model)
            }
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'pageTargetTagsIndex') {
                if (oldData !== 'pageTargetTagsIndex') {
                    this.defaultValue()
                }

                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        user_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('users/setList', {});
        },
        case_id() {},
    },
    methods: {
        searchUser() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.user_keyword.length > 2) {
                    self.$store.commit('users/setList', {});
                    self.$store.dispatch('users/getList', [30, 1, self.user_keyword]);
                }
            }, 650);
        },
        selectUser(user){
            this.user_keyword = user.name
            this.user_id = user.id
            this.$store.commit('users/setList', {});
            this.user_selected = true
            this.getData()
        },
        setEmptyUser() {
            this.user_keyword = ''
            this.user_id=''
            this.user_selected = false
            this.getData()
        },
        defaultValue() {
            this.items_per_page = 10;
            this.page = 0;
            this.keyword = '';
            this.$store.commit('targetTags/setList', {});
        },
        onChange (value) {
            console.log("onChange:begin")
            console.log({value})
            console.log("onChange:end")
            this.casesValue = value
        },
        getData() {
            // for dispatch get data
            if (this.route_name === 'pageTargetTagsIndex') {
                this.$store.dispatch('targetTags/getList', [this.items_per_page, (this.page + 1), this.keyword, this.user_id]);
                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('targetTags/setList', {});
                self.getData();
            }, 650);
        },
        openModal(open = true, data=null) {
            // hide scroll bar if true
            if(open) {
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            if (data){
                this.edit = true
                this.editData = data
                this.tmpKeyword = data.keyword
                this.tmpType = data.type
                this.model.case_id = data.case_id
                this.model.description = data?.description || ''
            }else{
                this.edit = false
                this.tmpKeyword = null
                this.tmpType = 'msisdn'
                // this.model.case_id = []
                // this.model.description = ''
                this.setEmptyModel(true)

            }
            this.openPopUp = !this.openPopUp
            this.errorTmpKeyword=''
            this.errorTmpType=''
        },
        setEmptyModel(all=true) {
            console.log('setEmptyModel')
            this.$nextTick(() => { this.$v.$reset(); });
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    if(["case_id"].indexOf(key) > -1)
                        this.model[Object.keys(this.model)[key]] = [];
                    else
                        this.model[Object.keys(this.model)[key]] = '';
            }
        },
        inputKeyword(event){
            const value = event.target.value
            this.errorTmpKeyword = ""

            const regexMail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
            let isNumber = value.match(/^-?\d+$/)
            
            let { is_msisdn, wording_msisdn } = this.msisdnValidation(value)
            let isEmail = value.match(regexMail)
            
            if (this.tmpType == 'msisdn') {
                if (!is_msisdn) 
                    this.errorTmpKeyword = wording_msisdn
            } else if (this.tmpType == 'email') {
                if (!isEmail) 
                    this.errorTmpKeyword = "Please fill with valid email"
            } else if (this.tmpType == 'name') {
                if (isEmail || is_msisdn || isNumber) 
                    this.errorTmpKeyword = "Please fill with valid name"
            }
        },
        submit(e){
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            }

            if (this.edit) {
                this.update()                
            } else {
                this.create()
            }
        },
        create(){
            EventEmit.$emit('showLoading', true);
            let thisModel = this.model
            // let model_data = Object.keys(this.model).map(
            //     (key) => (["case_id"].indexOf(key) == -1)?[JSON.stringify(this.model[key])]:this.model[key]
            // );       
            let datacase = this.model && this.model.case_id ? this.model.case_id : []
            console.log({datacase, thisModel})
            this.$store.dispatch('targetTags/create', [this.tmpKeyword, this.tmpType, JSON.stringify(datacase), this.model.description, this.model.notes])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // go to detail
                        // this.$router.push({ name: 'pageTargetTagsDetail', params: { id: resp.data.data.id } });

                        // stay in list
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Digital Trace successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
            this.openModal(false);
        },
        update(){
            EventEmit.$emit('showLoading', true);
            let id = this.editData.id
            this.$store.dispatch('targetTags/update', [id, this.model.description])
            .then((resp) => {
                if (resp.result === 'success') {
                    // go to detail
                    // this.$router.push({ name: 'pageTargetTagsDetail', params: { id: id } });

                    // stay in list
                    this.getData();

                    this.$swal.fire({
                        icon: 'success',
                        title: 'Digital Trace successfully updated!',
                        timer: 3000,
                    });
                } else {
                    if (resp.data.message) {
                        EventEmit.$emit('error', resp.data.message);
                    } else {
                        EventEmit.$emit('error');
                    }
                }
            });
            this.openModal(false);
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Digital Trace : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('targetTags/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        getNotes(open=true, target_tags_id=null,keyword=null) {
            this.$store.commit('targetTags/setNotesList', {});
            if(open){
                this.targetTagsIdSelected = target_tags_id
                this.targetTagsSelected = keyword
                this.$store.dispatch('targetTags/getNotesList', [this.targetTagsIdSelected]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes();
                this.setDefaulDataNotes();
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            // console.log(target_tags_id)
            this.$store.commit('targetTags/setIsLoadingNext', true);        
            this.$store.dispatch('targetTags/getNotesList', [this.targetTagsIdSelected, true]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('targetTags/createNotes', [this.targetTagsIdSelected, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.targetTagsIdSelected, this.targetTagsSelected);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.targetTagsIdSelected = null
            this.targetTagsSelected = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            // console.log('else')
            // this.dataNotes = [];
            // this.totalNotes = 0;
            // this.limitNotes = 10;
            // this.pageNotes=0;
            // this.totalPageNotes=1;
            const dataPagination = { 
                current_page: 1,
                has_next: false,
                has_previous: false,
                next_page_number: 1,
                per_page: 10,
                previous_page_number: null,
                total_data: 0,
                total_page: 1,
            }
            this.$store.commit('targetTags/setDataPagination', dataPagination);
        },  
        findOption(value) {
            // console.log('=====findOption')
            // console.log(value)
            const opt = this.case_management_list.data
            try {
                return opt.find(option => option['id'] === value)
            } catch (error) {
                // console.log('error')
                // console.log(error)
                return opt
            }
        },      
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
        this.defaultValue()
    },
    created() {
        this.getData();
    },
}
</script>


<style lang="sass">
.btn-close-search
    @apply absolute top-0 right-0 flex items-center justify-center
    width: 46px
    height: 46px
.box-find
    width: 100%
    max-width: 400px
    background-color: $color-secondary-500
    border-radius: 4px
    position: relative

.container-box-users
    top: 100%
    z-index: 100
    background-color: $color-secondary-700
    width: 100%

.box-users
    .item-user
        @apply .flex .items-center .p-2 .border-b .cursor-pointer
        &:hover 
            background-color: $color-secondary-600
        &:last-child
            border-bottom: 0px
.box-find-selected
    background-color: $color-secondary-600
    padding-top: 6px
    @apply .absolute .inset-0 .rounded .px-2 .border
    border-color: #2d3061

</style>